import { supabase } from "@/plugins/supabase";

const TABLE_NAME = "recipients"


const state = {
    recipients: [],
    initialized: false
};

const actions = {
    async addRecipient({commit, dispatch}, newRecipient) {
        return new Promise(async(resolve, reject) => {
            try {
                const { data, error } = await supabase
                    .from(TABLE_NAME)
                    .insert([
                        {...newRecipient}
                    ]).select();
                if(error) throw error
                
                commit('SET_RECIPIENT', data[0])
                resolve(data[0]);
            } catch(e) {
                reject({msg: 'Failed to add recipient', locale_str: 'store.configuration.add_failed'});
            }
        })

    },
    async removeRecipient({commit}, data) {
        return new Promise(async (resolve, reject) => {
            try {
                const { error } = await supabase
                    .from(TABLE_NAME)
                    .delete()
                    .eq('id', data.id)
                if(error) throw error
                commit('REMOVE_RECIPIENT', data)
                resolve();
            } catch(e) {
                reject({msg: "Failed to delete recipient", locale_str: 'store.remove_failed'})
            }
        })
    },
    async initializeRecipients({commit, state}) {
        return new Promise(async (resolve, reject) => {
            try {
                if(state.initialized) {
                    resolve();
                    return;
                }
                const { data, error } = await supabase
                    .from(TABLE_NAME)
                    .select();
                if (error) throw error
                
                commit('SET_RECIPIENTS', data);
                resolve();
            } catch(e) {
                reject({msg: 'Failed to initialize recipients', locale_str: 'store.configurations.init_failed'})
            }
        })
    }
}
const getters = {
    getRecipients: state => state.recipients ? state.recipients : [],
}
const mutations = {
    SET_RECIPIENTS(state, recipients) {
        state.recipients = recipients
        state.initialized = true;
    },
    REMOVE_RECIPIENT(state, recipient) {
        state.recipients.splice(state.recipients.indexOf(recipient), 1)
    },
    SET_RECIPIENT(state, recipient) {
        state.recipients.push(recipient);
    }
};
export default {
    state,
    getters,
    mutations,
    actions
  };