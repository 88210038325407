<template>
    <div class="otp-container">
        <v-text-field 
            v-for="(el, index) in otp" 
            :ref="`otp-${index}`" 
            hide-details 
            v-model="otp[index]" 
            :key="index" 
            outlined 
            :disabled="loading"
            class="otp-input mx-2 text-center" 
            maxlength="1" 
            @keydown="keyDown($event, index)" 
            @keyup="keyUp($event, index)" 
            @input="onInput($event, index)"></v-text-field>
    </div>
</template>

<script>
export default {
    name: 'OtpInput',
    props: {
        length: {
            type: Number,
            default: 6,
            required: true
        },
        value: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        loading: function (val, oldVal) {
            if(oldVal) {
                this.setFocus(this.currentIndex);
            }
        }
    },
    data: (instance) => ({
        otp: Array.from({ length: instance.length }, () => null),
        currentIndex: -1
    }),
    methods: {
        keyDown(e, index) {
            let key = e.keyCode;
            // if backspace
            if (key === 8 && index > 0) {
                this.otp[index] = null
                this.$nextTick(() => {
                    this.setFocus(index - 1)
                })
            }
            // if tab, arrow left, arrow right
            if (key === 9 || key === 37 || key === 39) {
                if (key === 37) {
                    if (index > 0) {
                        this.setFocus(index - 1)
                    }
                } else {
                    if (index < this.length - 1) {
                        this.setFocus(index + 1)
                    }
                }
            }
            if (this.isValidKey(key)) { 
                this.$refs[`otp-${index}`][0].$el.getElementsByTagName('input')[0].value='';
            }
            this.keycode = key
        },
        keyUp(e, index) {
            if (this.isValidKey(e.keyCode)) { 
                if (index < this.length - 1) {
                    this.setFocus(index + 1)
                }
            } else {
                e.preventDefault()
            }
        },
        isValidKey(key) {
            return (key >= 48 && key <= 57) || (key >= 96 && key <= 105)
        },
        setFocus(index) {
            this.currentIndex = index
            this.$nextTick(() => {
                let el = this.$refs[`otp-${index}`][0].$el.getElementsByTagName('input')[0]
                el.focus()
            })
        },
        onInput() {
            this.$emit('input', this.otp.join(''))
            if(this.otp.join('').length === this.length) {
                this.$emit('validate', this.otp.join(''))
            }
        }
    },
}
</script>

<style lang="scss">
.otp-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}
.otp-input {
    max-width: 56px;
    input {
        text-align: center !important;
        caret-color: transparent;
        &:hover {
            cursor: pointer
        }
    }
}
</style>