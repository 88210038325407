import { supabase } from "@/plugins/supabase";

const TABLE_NAME = 'segments'

const state = {
    segments: null,
};

const actions = {
    async addSegment({commit, dispatch}, segment) {
        return new Promise(async(resolve, reject) => {
            try {
                const { data, error } = await supabase
                    .from(TABLE_NAME)
                    .insert([
                        {...segment}
                    ]).select()
                if(error) throw error
                
                commit('SET_SEGMENT', data[0]);
                resolve(data[0]);
            } catch(e) {
                reject({msg: 'Failed to add segment', locale_str: 'store.segments.add_failed'});
            }
        })

    },
    async updateSegment({commit}, segment) {
        return new Promise(async(resolve, reject) => {
            try {
                const { error } = await supabase
                    .from(TABLE_NAME)
                    .update(segment)
                    .eq('id', segment.id)
                
                commit('UPDATE_SEGMENT')
                resolve(segment);
            } catch(e) {
                reject({msg: 'Failed to update segment', locale_str: 'store.update_failed'})
            }
        })
    },

    async removeSegment({commit}, segment) {
        return new Promise(async (resolve, reject) => {
            try {
                const { error } = await supabase
                    .from(TABLE_NAME)
                    .delete()
                    .eq('id', segment.id)
                if(error) throw error
                commit('REMOVE_SEGMENT', segment);
                resolve();
            } catch(e) {
                reject({msg: 'Failed to delete segment', locale_str: 'store.remove_failed'})
            }
        })
    },
    async initializeSegments({commit, state}) {
        return new Promise(async(resolve, reject) => {
            try {
                if(state.segments) {
                    return;
                }
                const { data, error } = await supabase
                    .from(TABLE_NAME)
                    .select()
                if(error) throw error
                commit('SET_SEGMENTS', data);
                resolve(data);
            } catch(e) {
                reject({msg: 'Failed to initialize segments', locale_str: 'store.segments.init_failed'})
            }
        });
    },
}
const getters = {
    getSegments: state => state.segments ? state.segments : [],
}
const mutations = {
    SET_SEGMENTS(state, segments) {
        state.segments = segments
    },
    SET_SEGMENT(state, segment) {
        state.segments.push(segment);
    },
    REMOVE_SEGMENT(state, segment) {
        state.segments.splice(state.segments.indexOf(segment), 1)
    },
    UPDATE_SEGMENT(state, segment) {
        const item = state.segments.find(s => s.id == segment.id)
        Object.assign(item, segment);
    }
};
export default {
    state,
    getters,
    mutations,
    actions
  };