import Vue from 'vue';

export const Focusable = {
    inserted: function (el, binding, vnode) {
        let input = el.getElementsByTagName('input')[0];
        if(binding.value) {
            Vue.nextTick(function() {
                input.focus()
            })
        }

    }
}