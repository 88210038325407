const TABLE_NAME = "companies"
import { supabase } from "@/plugins/supabase";

const state = {
    companies: [],
    initialized: false
};

const actions = {
    async addCompany({commit, dispatch}, newCompany) {
        return new Promise(async(resolve, reject) => {
            try {
                /*let supabaseUser = await supabase.auth.getUser();
                let user_id = supabaseUser.data.user.id;*/
                const { data, error } = await supabase.from(TABLE_NAME).insert([
                    {
                        ...newCompany
                    }
                ]).select()
                if(error) throw error;
                commit('SET_COMPANY', data[0])
                resolve(data[0]);
            } catch(e) {
                reject({msg: 'Failed to add company', locale_str: 'store.companies.add_failed'});
            }
        })

    },
    async updateCompany({commit}, company) {
        return new Promise(async (resolve, reject) => {
            try {
                const { error } = await supabase
                    .from(TABLE_NAME)
                    .update(company)
                    .eq('id', company.id)
                if(error) throw error
                commit('UPDATE_COMPANY', company)
                resolve(company)
            } catch(e) {
                reject({msg: 'Failed to update company', locale_str: 'store.update_failed'})
            }
        })
    },
    async removeCompany({commit}, data) {
        return new Promise(async (resolve, reject) => {
            try {
                const { error } = await supabase
                    .from(TABLE_NAME)
                    .delete()
                    .eq('id', data.id)
                if(error) throw error;
                commit('REMOVE_COMPANY', data)
                resolve();
            } catch(e) {
                reject({msg: "Failed to delete company", locale_str: 'store.remove_failed'})
            }
        })
    },
    async initializeCompanies({commit, state}) {
        return new Promise(async (resolve, reject) => {
            try {
                if(state.initialized) {
                    resolve();
                    return;
                }
                const { data, error } = await supabase.from(TABLE_NAME).select()
                if(error) throw error
                commit('SET_COMPANIES', data);
                resolve();
            } catch(e) {
                reject({msg: 'Failed to initialize companies', locale_str: 'store.companies.init_failed'})
            }
        })
    }
}
const getters = {
    getCompanies: state => state.companies ? state.companies : [],
}
const mutations = {
    SET_COMPANIES(state, data) {
        state.companies = data
        state.initialized = true;
    },
    UPDATE_COMPANY(state, data) {
        const item = state.companies.find(s => s.id == data.id)
        Object.assign(item, data)
    },
    REMOVE_COMPANY(state, data) {
        state.companies.splice(state.companies.indexOf(data), 1)
    },
    SET_COMPANY(state, data) {
        state.companies.push(data);
    }
};
export default {
    state,
    getters,
    mutations,
    actions
  };