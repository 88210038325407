
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '@/plugins/supabase';
const PURCHASE_ORDER_BUCKET_NAME = 'purchase-orders'
const state = {
    files: []
  };
const actions = {
    async uploadFile({ commit }, file) {
        return new Promise(async (resolve, reject) => {
            const fileName = uuidv4();
            const { data, error } = await supabase.storage
                .from(PURCHASE_ORDER_BUCKET_NAME)
                .upload(`purchase-orders/${fileName}`, file);

            if(error) reject(error)
            resolve({fileName, originalName: file.name});
        })
    },
}
const getters = {
}
const mutations = {
};
export default {
    state,
    getters,
    mutations,
    actions
  };