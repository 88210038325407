import { supabase } from "@/plugins/supabase";

/*const getDefaultOrder = () => {
    return {
        "recipient": "carl.g.gabell@gmail.com",
        "project_name": "Projekt 1",
        "project_number": "Testprojekt",
        "project_part": "1A",
        "workplace_name": "Workplace 1",
        "delivery_address": "Storgatan 33",
        "post_address": "458 58 Upplandsgrus",
        "desired_delivery_date": "2023-01-17",
        "unloading_time": "12:00",
        "responsible_for_unloading": "Martin Martinsson",
        "unloading_phone_nr": "0707 40 13 14",
        "order_rows": [],
        "company_id": null
    }
}*/
const getDefaultOrder = () => {
    return {
        "recipient": "",
        "project_name": "",
        "project_number": "",
        "project_part": "",
        "workplace_name": "",
        "delivery_address": "",
        "post_address": "",
        "desired_delivery_date": "",
        "unloading_time": "07:00",
        "responsible_for_unloading": "",
        "unloading_phone_nr": "",
        "order_rows": [],
        "company_id": null
    }
}

const state = getDefaultOrder();

const actions = {
    async generateOrder({ commit, state, getters }) {
        return new Promise(async (resolve, reject) => {
            let order = getters.getOrder;
            try {
                const createResponse = await supabase.functions.invoke('create-order', {
                    body: order
                })
                if(createResponse.error) throw createResponse.error;
                
                commit('SET_ORDER', createResponse.data.order);
                commit('RESET_NEW_ORDER')
                resolve();
            } catch(e) {
                console.error(e);
                reject(e);
            }
        })
    },
    async resendPdf({ commit }, body) {
        console.log(body);
        const resendResponse = await supabase.functions.invoke("resend-order", {
          body,
        });
        if (resendResponse.error) throw resendResponse.error;
        return true;
      },
    async updateOrderRow({ commit }, editedRow) {
        commit('UPDATE_ORDER_ROW', editedRow)
    }
}
const getters = {
    getOrder: state => state ? state : null,
    getOrderRows: state => state.order_rows,
    getOrderSummary: state => {
        
    }
}
const mutations = {
    ADD_ORDER_ROW(state, value) {
        state.order_rows.push(value);
    },
    UPDATE_ORDER_ROW(state, editedRow) {
        const item = state.order_rows.find(row => row.id == editedRow.id)
        Object.assign(item, editedRow);
    },
    DELETE_ORDER_ROW(state, row) {
        state.order_rows.splice(state.order_rows.indexOf(row), 1);
    },
    RESET_NEW_ORDER(state) {
        Object.assign(state, getDefaultOrder());
    }
};
export default {
    state,
    getters,
    mutations,
    actions
  };