import { extend, } from 'vee-validate';
import { required, email, min, confirmed } from "vee-validate/dist/rules";
import i18n from './i18n'

extend('required', {
    ...required,
    message: (_, values) => {
        return i18n.t('validate.required_field', values)
    },
})
extend('email', {
    ...email,
    message: (_, values) => {
        return i18n.t('validate.email', values)
    },
})
extend('min', {
    ...min,
    message: (_, values) => {
        return i18n.t('validate.min', values)
    }
})
extend('confirmed', {
    ...confirmed,
    message: (_, values) => {
        return i18n.t('validate.confirmation', values)
    }
})