import Vue from 'vue'
import { supabase } from "@/plugins/supabase";

const state = {
    productGroups: [],
}
const assembleSorted = (groups, order) => {
    return groups.map(group => {
        group.sort_order = order.sort_order?.hasOwnProperty(group.id) ? order.sort_order[group.id] : 0
        return group
    })
}
const actions = {
    async addProductGroup({commit}, productGroup) {
        return new Promise(async(resolve, reject) => {
            try {
                const { data, error } = await supabase.from('product_groups').insert([
                    {
                        ...productGroup
                    }
                ]).select()
                if(error) throw error
                
                commit('SET_PRODUCT_GROUP', data[0])
                resolve(data[0]);
            } catch(e) {
                reject({msg: 'Failed to add product group', locale_str: 'store.configuration.add_failed'});
            }
        })
    },
    async updateProductGroupsSortOrder({commit, state}, productGroups) {
        let sortOrder = productGroups.map((group, index) => {
            return {
                id: group.id,
                sort_order: index
            }
        }).reduce((a, group) => { 
            a[group.id] = group.sort_order 
            return a
        }, {})
        const orderedGroups = assembleSorted(productGroups, sortOrder)
        commit('SET_PRODUCT_GROUPS', orderedGroups)
        try {
            const { error } = await supabase
                .from('product_groups_order')
                .update({ sort_order: sortOrder })
                .eq('id', 1)
            if(error) throw error
            Promise.resolve(orderedGroups);
        } catch(e) {
            Promise.reject({msg: 'Failed to update product groups sort order', locale_str: 'store.configuration.update_failed'})
        }
    },
    async initializeProductGroups({commit, state}) {
        if(state.productGroups.length > 0) {
            Promise.resolve()
            return;
        }
        try {
            const { data: order, error: orderError} = await supabase
                .from('product_groups_order')
                .select()
                .eq('id', 1)
                .single();
            if(orderError) throw orderError
            
            const { data: groups, error: groupError} = await supabase
            .from('product_groups')
            .select();
            if(groupError) throw groupError

            const orderedGroups = assembleSorted(groups, order)
            
            commit('SET_PRODUCT_GROUPS', orderedGroups)
            Promise.resolve(orderedGroups);
        } catch(e) {
            Promise.reject({msg: 'Failed to initialize product groups', locale_str: 'store.configuration.init_failed'})
        }

    }
}
const getters = {
    getProductGroups: state => state.productGroups
}

const mutations = {
    SET_PRODUCT_GROUP(state, data) {
        state.productGroups.push(data);
    },
    SET_PRODUCT_GROUPS(state, data) {
        state.productGroups = data;
    },
}

export default {
    state,
    actions,
    getters,
    mutations
}
