<template>
    <v-menu
    v-model="popover"
    :close-on-content-click="false"
    left
    :nudge-width="360"
    :max-width="getMaxWidth"
    offset-y
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
        icon
        :x-large="$vuetify.breakpoint.mdAndUp"
        dark
        color="black"
        v-bind="attrs"
        v-on="on">
        <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
    </template>

    <v-card>
        <v-list>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{ $t('file_cabinet.help_files') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-icon>mdi-file-multiple</v-icon>
            </v-list-item-action>
        </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list v-if="!loadingFiles">
            <v-list-item v-for="file in getCabinetFiles" :key="file.id">
                <v-list-item-avatar>
                    <v-icon large>mdi-file-pdf-box</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title><a class="text-body-2" href="#" :title="file.file_name" @click.prevent="downloadFile(file)">{{ file.file_name }}</a></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list v-if="loadingFiles">
            <v-skeleton-loader
                v-for="n in 5"
                type="list-item-avatar"
                :key="n"></v-skeleton-loader>
        </v-list>
    </v-card>
    </v-menu>
</template>
<script>
import UponorLogo from '@/components/logo-uponor.vue'
import { supabase } from "@/plugins/supabase";
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'FileListPopover',
    components: { UponorLogo  },
    data() {
        return {
            popover: false,
            loadingFiles: false
        }
    },
    watch: {
        popover: function (val) {
            if(val) 
                this.loadFiles()
        }
    },
    computed: {
        ...mapGetters(['getCabinetFiles']),
        getMaxWidth() {
            return this.$vuetify.breakpoint.mdAndUp ? 650 : screen.width - 25
        }
    },
    methods: {
        ...mapActions(['initializeCabinetFiles']),
        async loadFiles() {
            this.loadingFiles = true;
            await this.initializeCabinetFiles();
            this.loadingFiles = false;
        },
        async downloadFile(file) {
            const { data, error } = await supabase
                .storage
                .from('file-cabinet')
                .download(file.path);
            if(error) throw error;
            let link = document.createElement("a")
            const url = window.URL.createObjectURL(data)
            link.href = url
            let fileName = file.file_name
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)            
        }
    }
}
</script>