import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":360,"max-width":_vm.getMaxWidth,"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","x-large":_vm.$vuetify.breakpoint.mdAndUp,"dark":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("mdi-card-account-phone")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(_vm._s(_vm.$t('orders.contact_us')))]):_vm._e()],1)]}}]),model:{value:(_vm.popover),callback:function ($$v) {_vm.popover=$$v},expression:"popover"}},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemAvatar,[_c('UponorLogo')],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('orders.any_questions')))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.$t('orders.contact_us')))])],1),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-email-edit")])],1)],1)],1),_c(VDivider),_c(VList,[_c(VListItem,[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-phone")])],1),_c(VListItemTitle,[_c('a',{attrs:{"href":"tel:010-3341000","role":"button"}},[_vm._v("010-3341000")])])],1),_c(VListItem,[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-email")])],1),_c(VListItemTitle,[_c('a',{attrs:{"href":"mailto:kundtjanst.vvs@uponor.com","role":"button"}},[_vm._v("kundtjanst.vvs@uponor.com")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }