import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './auth'
import language from './lang'
import users from './users'
import segments from './segments'
import customers from './customers'
import configurations from './configurations'
import orders from './orders'
import order from './order'
import files from './files'
import recipients from './recipients'
import companies from './companies'
import productGroups from './product_groups'
import fileCabinet from './file_cabinet'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    getLoading(state) {
      return state.loading
    }
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    }
  },
  actions: {},
  modules: {
    authentication,
    language,
    users,
    segments,
    customers,
    configurations,
    orders,
    order,
    files,
    recipients,
    companies,
    productGroups,
    fileCabinet
  }
})
