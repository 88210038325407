import { render, staticRenderFns } from "./CompanyIcon.vue?vue&type=template&id=d28402cc&scoped=true&"
var script = {}
import style0 from "./CompanyIcon.vue?vue&type=style&index=0&id=d28402cc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d28402cc",
  null
  
)

export default component.exports