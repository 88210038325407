import { supabase } from "@/plugins/supabase";

const ORDERS_TABLE = 'orders'
const state = {
    orders: [],
};

const actions = {
    async updateOrder({commit}, data) {
        return new Promise(async(resolve, reject) => {
            try {
                const { error } = await supabase
                    .from(ORDERS_TABLE)
                    .update(data)
                    .eq('id', data.id)
                if(error) throw error
                commit('UPDATE_ORDER')
                resolve(data);
            } catch(e) {
                reject({msg: 'Failed to update order', locale_str: 'store.update_failed'})
            }
        })
    },
    async updateOrderStatus({commit}, cancelData) {
        return new Promise(async(resolve, reject) => {
            try {
                const { data, error } = await supabase.functions.invoke('cancel-order', {
                    body: {
                        order_id: cancelData.order.id,
                    }
                })
                if(error) throw error;
                commit('UPDATE_ORDER', {id: data.order.id, ...data.order})
                resolve();
            } catch(e) {
                reject({msg: 'Failed to update order', locale_str: 'store.update_failed'})
            }
        })
    },
    async initializeOrders({commit, state, getters}, isAdmin) {
        let ownerId = getters.getUserId;
        try {
            if(state.orders.length > 0) {
                return;
            }
            if(isAdmin) {
                let {data, error} = await supabase
                    .from(ORDERS_TABLE)
                    .select(`
                    id,
                    hash,
                    created_at,
                    created_by_id,
                    project_name,
                    project_part,
                    project_number,
                    desired_delivery_date,
                    delivery_date,
                    order_rows,
                    status,
                    workplace_name,
                    delivery_address,
                    post_address,
                    unloading_time,
                    responsible_for_unloading,
                    unloading_phone_nr,
                    companies(id, name, invoice_address, org_nr),
                    users(id, name, email, phone_number)`)
                if( error ) throw error;
                
                commit('SET_ORDERS', data);
            } else {
                let {data, error} = await supabase
                    .from(ORDERS_TABLE)
                    .select()
                    .eq('created_by_id', ownerId)
                if( error ) throw error;
                
                commit('SET_ORDERS', data);
            }

            Promise.resolve();
        } catch(e) {
            Promise.reject({msg: 'Failed to initialize orders', locale_str: 'store.orders.init_failed'});
        }
    },
}
const getters = {
    getOrders: state => state.orders ? state.orders : []
}
const mutations = {
    SET_ORDERS(state, data) {
        state.orders = data
    },
    SET_ORDER(state, data) {
        state.orders.push(data);
    },
    REMOVE_ORDER(state, data) {
        state.orders.splice(state.orders.indexOf(data), 1)
    },
    UPDATE_ORDER(state, data) {
        const item = state.orders.find(s => s.id == data.id)
        Object.assign(item, data);
    }
};
export default {
    state,
    getters,
    mutations,
    actions
  };