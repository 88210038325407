import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import sv from 'vuetify/es5/locale/sv';

import { HomeIcon, SettingsIcon, BookmarkIcon, UsersIcon, ToolsIcon, PlusIcon, ShoppingCartIcon, BookOpenIcon, CompanyIcon, CategoryIcon, ProductConfiguration } from '@/assets/icons/index'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { sv },
        current: 'sv'
    },
    icons: {
        values: {
            home: {
                component: HomeIcon
            },
            settings: {
                component: SettingsIcon
            },
            bookmark: {
                component: BookmarkIcon
            },
            users: {
                component: UsersIcon
            },
            tools: {
                component: ToolsIcon
            },
            plus: {
                component: PlusIcon
            },
            shoppingCart: {
                component: ShoppingCartIcon
            },
            bookOpen: {
                component: BookOpenIcon
            },
            company: {
                component: CompanyIcon
            },
            category: {
                component: CategoryIcon
            },
            product_configuration: {
                component: ProductConfiguration
            }
        }
    },
    theme: {
        themes: {
            light: {
                primary: '#0062C8',
                secondary: '#002E5D',
                accent: '#FF8200',
                error: '#b71c1c',
                secondary_blue: '#A2BCD2'
            }
        },
        options: {
            customProperties: true
        }
    }
});
