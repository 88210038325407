const state = {
    appLanguage: localStorage.getItem('appLanguage') || process.env.VUE_APP_I18N_LOCALE || 'se'
};
const actions = {
    setLanguage({ commit }, language) {
        commit('SET_APPLANGUAGE', language)
    }
}
const getters = {
    getAppLanguage: (state) => "se"
}
const mutations = {
    SET_APPLANGUAGE(state, language) {
        state.appLanguage = language;
        localStorage.setItem("appLanguage", language);
      }
};
export default {
    state,
    getters,
    mutations,
    actions
  };