import Vue from 'vue'
import VueRouter from 'vue-router'

// Auth views
import Authentication from '@/views/authentication/Authentication.vue'
import Signin from '@/views/authentication/Signin.vue';
import ForgotPassword from '@/views/authentication/ForgotPassword.vue'
import SetPassword from '@/views/authentication/SetPassword.vue'
import AcceptInvitation from '@/views/authentication/AcceptInvitation.vue'

import Orders from '@/views/orders/Orders'

// Supabase
import { supabase } from "@/plugins/supabase";

Vue.use(VueRouter)

function loadPage(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`
    );
}

const routes = [
  {
    path: '/accept-invitation',
    component: AcceptInvitation,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/confirm-reset',
    component: loadPage('authentication/ConfirmReset'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/set-password',
    component: SetPassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/auth',
    components: {
      default: Authentication,
      auth: Signin
    },
    children: [
      {
        path: '/',
        component: Signin
      },
      {
        path: 'forgot',
        component: ForgotPassword
      },
    ]
  },
  {
    path: '/ahlsell',
    component: loadPage('orders/ValidateOrder')
  },
  {
    path: '/orders/validation',
    redirect: '/ahlsell'
  },
  {
    path: '/',
    component: function () {
      return import('../views/Home.vue')
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/orders',
        name: 'Orders',
        component: function() {
          return import('../views/orders/Orders.vue')
        },
        meta: {
          breadCrumb: [
            {
              text: 'bread_crumbs.orders.home'
            }
          ]
        }
      },
      {
        path: '/settings/users',
        name: 'Users',
        component: function() {
          return import('../views/settings/UserAccounts.vue')
        },
        meta: {
          requiresAdmin: true,
          breadCrumb: [
            {
              text: 'bread_crumbs.settings.users'
            }
          ]
        }
      },
      {
        path: '/settings/recipients',
        name: 'Recipients',
        component: function() {
          return import('../views/settings/Recipients.vue')
        },
        meta: {
          requiresAdmin: true,
          breadCrumb: [
            {
              text: 'bread_crumbs.settings.recipients'
            }
          ]
        }
      },
      {
        path: '/settings/companies',
        name: 'Companies',
        component: function() {
          return import('../views/settings/Companies.vue')
        },
        meta: {
          requiresAdmin: true,
          breadCrumb: [
            {
              text: 'bread_crumbs.settings.companies'
            }
          ]
        }
      },
      {
        path: '/configurations',
        name: 'Configuration',
        component: function() {
          return import('../views/configuration/Configurations.vue')
        },
        meta: {
          requiresAdmin: true,
          breadCrumb: [
            {
              text: 'bread_crumbs.configurations.home'
            }
          ]
        }
      },
      {
        path: '/configurations/new/:id?',
        name: 'New Configuration',
        component: function() {
          return import('../views/configuration/NewConfiguration.vue')
        },
        meta: {
          requiresAdmin: true,
          breadCrumb: [
            {
              text: 'bread_crumbs.configurations.home',
              to: {name: 'Configuration'},
            }, 
            {
              text: 'bread_crumbs.configurations.new'
            }
          ]
        }
      },
      {
        path: '/product-groups',
        name: 'Product Groups',
        component: function() {
          return import('../views/configuration/ProductGroups.vue')
        },
        meta: {
          requiresAdmin: true,
          breadCrumb: [
            {
              text: 'bread_crumbs.product_groups.home',
            }
          ]
        }
      },
      {
        path: '/orders/view/:id',
        name: 'ViewOrder',
        component: function() {
          return import('../views/orders/ViewOrder.vue')
        },
        meta: {
          breadCrumb: [
            {
              text: 'bread_crumbs.orders.home',
              to: {name: 'Orders'}
            },
            {
              text: 'bread_crumbs.orders.view'
            }
          ]
        }
      },
      {
        path: '/orders/new',
        name: 'New Order',
        component: function() {
          return import('../views/orders/NewOrder')
        },
        meta: {
          breadCrumb: [
            {
              text: 'bread_crumbs.orders.home',
              to: {name: 'Orders'}
            },
            {
              text: 'bread_crumbs.orders.new'
            }
          ]
        }
      },
      {
        path: '/orders/confirmation',
        name: 'Order Confirmation',
        component: function() {
          return import('../views/orders/OrderSentConfirmation.vue')
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  const sessionResult = await supabase.auth.getSession();
  const user = await supabase.auth.getUser();
  const currentSession = sessionResult.data?.session;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let isAdmin = false;
  if(currentSession) {
    const claims = currentSession.user?.app_metadata;
    isAdmin = claims.claims_admin;
  }
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  
  if((requiresAuth && !currentSession) || (requiresAdmin && !isAdmin)) {
    next('/auth')
  } else {
    if(to.path == '/') next('/orders')
    next();
  }
})
export default router
