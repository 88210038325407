import Vue from 'vue'
import { supabase } from "@/plugins/supabase";
import { v4 as uuidv4 } from 'uuid';

const TABLE_NAME = 'file_cabinet'
const BUCKET_NAME = 'file-cabinet'

const state = {
    files: []
}
const actions = {
    async uploadCabinetFile({ commit }, file) {
        const filename = uuidv4();
        try {
            const { data: uploadData, error: uploadError } = await supabase.storage
                .from(BUCKET_NAME)
                .upload(`${filename}`, file);
            if(uploadError) throw uploadError
            const { data: insertData, error: insertError } = await supabase
                .from(TABLE_NAME)
                .insert({
                    path: `${BUCKET_NAME}/${filename}`,
                    file_name: file.name,
                }).
                single()
            if (insertError) throw insertError
            commit('ADD_FILE', insertData)
            return Promise.resolve();
        } catch(e) {
            return Promise.reject({msg: 'Failed to upload file', locale_str: 'file_cabinet.errors.upload_failed'});
        }
    },
    async initializeCabinetFiles({commit, state}) {
        try {
            if(state.files.length > 0) {
                Promise.resolve()
                return;
            }
            const { data, error } = await supabase
                .from(TABLE_NAME)
                .select()
            if(error) throw error;
            commit('SET_FILES', data);
            Promise.resolve();
        } catch(e) {
            Promise.reject({msg: 'Failed to initialize files', locale_str: 'file_cabinet.errors.init_failed'});
        }
    }
}

const getters = {
    getCabinetFiles: state => state.files
}

const mutations = {
    ADD_FILE(state, file) {
        state.files.push(file)
    },
    SET_FILES(state, files) {
        state.files = files
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}