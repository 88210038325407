import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":false,"left":"","nudge-width":360,"max-width":_vm.getMaxWidth,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","x-large":_vm.$vuetify.breakpoint.mdAndUp,"dark":"","color":"black"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-help-circle-outline")])],1)]}}]),model:{value:(_vm.popover),callback:function ($$v) {_vm.popover=$$v},expression:"popover"}},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('file_cabinet.help_files')))])],1),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-file-multiple")])],1)],1)],1),_c(VDivider),(!_vm.loadingFiles)?_c(VList,_vm._l((_vm.getCabinetFiles),function(file){return _c(VListItem,{key:file.id},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-file-pdf-box")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('a',{staticClass:"text-body-2",attrs:{"href":"#","title":file.file_name},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(file)}}},[_vm._v(_vm._s(file.file_name))])])],1)],1)}),1):_vm._e(),(_vm.loadingFiles)?_c(VList,_vm._l((5),function(n){return _c(VSkeletonLoader,{key:n,attrs:{"type":"list-item-avatar"}})}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }