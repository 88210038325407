<template>
    <v-menu
    v-model="popover"
    :close-on-content-click="false"
    :nudge-width="360"
    :max-width="getMaxWidth"
    left
    offset-y
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
        color="primary"
        :x-large="$vuetify.breakpoint.mdAndUp"
        dark
        v-bind="attrs"
        v-on="on"
        >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-card-account-phone</v-icon>
        <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('orders.contact_us') }}</span>
        </v-btn>
    </template>

    <v-card>
        <v-list>
        <v-list-item>
            <v-list-item-avatar>
            <UponorLogo />
            </v-list-item-avatar>

            <v-list-item-content>
            <v-list-item-title>{{ $t('orders.any_questions') }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('orders.contact_us') }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-icon>mdi-email-edit</v-icon>
            </v-list-item-action>
        </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
        <v-list-item>
            <v-list-item-action>
                <v-icon>mdi-phone</v-icon>
            </v-list-item-action>
            <v-list-item-title><a href="tel:010-3341000" role="button">010-3341000</a></v-list-item-title>
        </v-list-item>

        <v-list-item>
            <v-list-item-action>
                <v-icon>mdi-email</v-icon>
            </v-list-item-action>
            <v-list-item-title><a href="mailto:kundtjanst.vvs@uponor.com" role="button">kundtjanst.vvs@uponor.com</a></v-list-item-title>
        </v-list-item>
        </v-list>
    </v-card>
    </v-menu>
</template>
<script>
import UponorLogo from '@/components/logo-uponor.vue'
export default {
    name: 'ContactUsPopover',
    components: { UponorLogo  },
    data() {
        return {
            popover: false,
        }
    },
    computed: {
        getMaxWidth() {
            return this.$vuetify.breakpoint.mdAndUp ? 650 : screen.width - 25
        }
    }
}
</script>