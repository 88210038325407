<template>
  <div>
    <div class="title mt-5 mb-2">Logga in</div>
    <ValidationObserver ref="observer">
      <v-form @submit.prevent="submit">
          <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
          <v-text-field
              v-model="email"
              :error-messages="errors"
              :label="$t('sign_in.email')"
              type="email"
              required></v-text-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Password" rules="required">
          <v-text-field
              v-model="password"
              :error-messages="errors"
              :label="$t('sign_in.password')"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPass = !showPass"
              required
              :type="showPass ? 'text' : 'password'"
          ></v-text-field>
          </ValidationProvider>
          <div class="text-center">
            <div class="red--text mb-2">{{getAuthErrors != null ? $t('sign_in.incorrect_password') : ''}}</div>
            <v-btn :loading="loading.signin" type="submit" color="primary" block>
                {{ $t('sign_in.sign_in') }}
            </v-btn>
          </div>
          <div class="mt-5 caption text-right">
            <router-link to="/auth/forgot">
              {{ $t('sign_in.forgot_password') }}
            </router-link>
          </div>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from "vuex";
import { Focusable } from '@/components/directives/focus'
setInteractionMode('eager')

extend('email', {
  ...email,
  message: 'Email must be valid'
})
export default {
  directives: { focus: Focusable },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    email: '',
    password: null,
    showPass: false,
    error: null,
    loading: {
      signin: false
    }
  }),
  computed: {
    params() {
      return {
        email: this.email,
        password: this.password
      }
    },
    ...mapGetters([
      'getAuthErrors'
    ])
  },
  methods: {
    ...mapActions(['signInAction']),
    async submit() {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        await this.login(this.params)
      }
    },
    toggleReset() {
      this.$emit('toggleview')
    },
    clear() {
      this.email = ''
      this.password = null
      this.$refs.observer.reset()
    },
    async login(params) {
      try {
        this.loading.signin = true;
        const res = await this.signInAction(params)
        if(res) {
          this.$router.push('/orders')
        }
      } catch(e) {

      } finally {
        this.loading.signin = false;
      }

    }
  }
}
</script>
