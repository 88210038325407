import { render, staticRenderFns } from "./BookOpenIcon.vue?vue&type=template&id=c9c3c966&scoped=true&"
var script = {}
import style0 from "./BookOpenIcon.vue?vue&type=style&index=0&id=c9c3c966&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9c3c966",
  null
  
)

export default component.exports