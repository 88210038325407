<template>
    <div class="bg d-flex flex-md-row flex-grow-1">
        <v-row class="d-flex justify-center align-center">
            <v-col xl="3">
                <v-card class="pa-4">
                    <v-row class="mt-2">
                        <v-col class="d-flex justify-center">
                            <UponorLogo :height="70"/>
                        </v-col>
                    </v-row>
                    <v-card-title class="text-h5 justify-center">
                        Prefab Online
                    </v-card-title>
                    <v-card-text class="text-subtitle-1">
                        <p>{{ $t('accept_invitation.information') }}</p>
                        <OtpInput :length="6" v-model="otp" @validate="validateOtp" :loading="loadingOtp" @input="error = false"></OtpInput>
                        <div v-if="error" class="text-center mt-1 red--text">{{$t('accept_invitation.invalid_code')}}</div>
                        <v-row class="mt-1">
                            <v-col v-if="loadingOtp" cols="12" class="d-flex flex-column align-center">
                                <v-subheader>{{ $t('accept_invitation.please_wait_message') }}</v-subheader>
                                <v-progress-circular
                                    size="42"
                                    indeterminate
                                    color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    
                </v-card>                
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import UponorLogo from '@/components/logo-uponor.vue'
import OtpInput from '@/components/otp-input/OtpInput.vue'
export default {
    components: { UponorLogo, OtpInput },
    data: () => ({
       otp: '',
       loadingOtp: false,
       error: false
    }),
    computed: {
        acceptLink() {
            return this.$route.query.confirmation_url
        }
    },
    methods: {
        ...mapActions(['verifyOtp']),
        async validateOtp() {
            this.loadingOtp = true
            let success = false
            try {
                success = await this.verifyOtp({email: this.$route.query.email, token: this.otp, type: 'invite'})
                this.error = false
            } catch(e) {
                this.error = true
            } finally {
                this.loadingOtp = false
            }
            if(success) {
                this.$router.push('/set-password')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.bg {
  background: url('~@/assets/IM2_3434_splash_min.jpg') no-repeat center center fixed; 
  background-size: cover;
}
.layout-side {
  width:400px;
}
.layout-content {
  max-width:480px;
}
</style>