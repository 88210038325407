<template>
  <div class="bg d-flex pa-5 flex-md-row flex-grow-1">
      <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8" lg="5" xl="3">
              <v-card class="pa-4">
                  <v-row class="mt-2">
                      <v-col class="d-flex justify-center">
                          <UponorLogo :height="70"/>
                      </v-col>
                  </v-row>
                  <v-card-title class="text-h5 justify-center">
                      Prefab Online
                  </v-card-title>
                  <v-card-text class="text-subtitle-1">
                      <p>{{ $t('set_password.title') }}</p>
                      <validation-observer ref="observer" v-slot="{invalid}">
                      <v-form @submit.prevent="submit">
                        <v-row>
                              <v-col cols="12">
                                  <ValidationProvider v-slot="{ errors, validate }" name="Password" rules="required|min:6">
                                  <v-text-field 
                                      ref="password"
                                      v-model="password" 
                                      :hide-details="hideDetails"
                                      :error-messages="errors" 
                                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                      @click:append="showPass = !showPass"
                                      required
                                      @focus="passwordFocused = true"
                                      @blur="passwordFocused = false"
                                      @input="validate"
                                      :type="showPass ? 'text' : 'password'"
                                      :label="$t('settings.users.table.headers.password') + ' *'">
                                      </v-text-field>
                                  </ValidationProvider>
                              </v-col>
                          </v-row>
  
                          <div class="text-center">
                          <div class="red--text mb-2">{{getResetError != null ? "Failed to reset error! Are you sure the email you have entered is registered as a user?": ''}}</div>
                          <v-btn :loading="submitting" :disabled="invalid" type="submit" color="primary" block>
                              {{ $t('accept_invitation.confirm_password') }}
                          </v-btn>
                          </div>
                      </v-form>
                  </validation-observer>
                  </v-card-text>
                  
              </v-card>                
          </v-col>
      </v-row>
  </div>
</template>

<script>
import { email, confirmed } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from "vuex";
import UponorLogo from '@/components/logo-uponor.vue'
setInteractionMode('eager')
extend('confirmed', {
    ...confirmed,
    message: "Passwords don't match!"
})
extend('email', {
  ...email,
  message: 'Email must be valid'
})
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    UponorLogo
  },
  data: () => ({
    email: '',
    password: null,
    passwordFocused: false,
    showPass: false,
    submitting: false,
  }),
  computed: {
    params() {
      return {
        email: this.email,
      }
    },
    hideDetails: function() {
      return this.passwordFocused
    },
    ...mapGetters([
      'getResetError'
    ])
  },
  methods: {
    ...mapActions(['setUserPassword']),
    async submit() {
      this.submitting = true;
      const valid = await this.$refs.observer.validate()
      if (valid) {
        let res = await this.setUserPassword(this.password);  
        this.submitting = false;
        if (res) {
          this.$router.push('/orders')
        }
      }
    },
    clear() {
      this.password = null;
      this.$refs.observer.reset()
    },
  }
}
</script>
</script>
<style lang="scss" scoped>
.bg {
  background: url('~@/assets/IM2_3434_splash_min.jpg') no-repeat center center fixed; 
  background-size: cover;
}
.layout-side {
  width:400px;
}
.layout-content {
  max-width:480px;
}
</style>