import { supabase } from "@/plugins/supabase";
const TABLE_NAME = 'users';
const state = {
    users: [],
    initialized: false
};

const actions = {
    async setUser({commit}, user) {
        return new Promise(async(resolve, reject) => {
            try {
                const { data, error } = await supabase
                    .from(TABLE_NAME)
                    .update(user)
                    .eq('id', user.id)
                    .select();
                commit('UPDATE_USER', user);
                resolve(user);
            } catch(e) {
                reject({msg: "Failed to update user", locale_str: 'admin.vuex.error_update_user'})
            }
        })
    },
    async addUser({commit, dispatch}, user) {
        return new Promise(async(resolve, reject) => {
            try {
                const { data, error } = await supabase.functions.invoke('invite-user', {
                    body: { ...user, redirect_to: window.location.origin + '/auth/set-password' }
                })
                if(error) throw error
                commit('SET_USER', {email: data.user.email, id: data.user.id, role: data.user.role})
                resolve(data.payload);
            } catch(e) {
                reject({msg: "Failed to add user", locale_str: 'settings.users.errors.failed_to_add_user'})
            }       
            

        })

    },
    async removeUser({commit}, { user, block}) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data, error } = await supabase.functions.invoke('block-user', {
                    body: {user_id: user.id, block}
                })
                if(error) throw error
                commit('BLOCK_USER', data)
                resolve();
            } catch(e) {
                reject({msg: "Failed to delete user", locale_str: 'admin.vuex.error_delete_user'})
            }
        })
    },
    async initializeUsers({commit, state}) {
        if(state.initialized)
            return;
        try {
            const { data, error } = await supabase
                .from(TABLE_NAME)
                .select()
            if(error) throw error
            state.initialized = true;
            commit('SET_USERS', data);
            Promise.resolve(data);
        } catch(e) {
            Promise.reject({msg: 'Failed to initialize users', locale_str: 'store.configurations.init_failed'})
        }
    },
}
const getters = {
    getUsers: state => state.users ? state.users : [],
}
const mutations = {
    SET_USERS(state, users) {
        state.users = users
    },
    UPDATE_USER(state, user) {
        const item = state.users.find(u => u.email == user.email)
        Object.assign(item, user);
    },
    BLOCK_USER(state, user) {
        state.users.find(u => u.id == user.user_id).blocked = user.block
    },
    SET_USER(state, user) {
        state.users.push(user);
    }
};
export default {
    state,
    getters,
    mutations,
    actions
  };