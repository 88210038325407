import { supabase } from "@/plugins/supabase";
const CUSTOMER_TABLE = 'customers'

const state = {
    customers: null,
};

const actions = {
    async addCustomer({commit, dispatch}, customerData) {
        return new Promise(async(resolve, reject) => {
            try {
                let user_id = getters.getUserId;
                const {data, error } = await supabase
                    .from(CUSTOMER_TABLE)
                    .insert([
                        {user_id, ...customerData}
                    ])
                    .select()
                if(error) throw error;
                commit('SET_CUSTOMER', data);
                resolve();
            } catch(e) {
                reject({msg: 'Failed to add customer', locale_str: 'store.customers.add_failed'});
            }
        })
    },
    async updateCustomer({commit}, customer) {
        return new Promise(async(resolve, reject) => {
            try {
                const { error } = await supabase
                    .from(CUSTOMER_TABLE)
                    .update(customer)
                    .eq('id', customer.id)
                if(error) throw error;
                commit('UPDATE_CUSTOMER')
                resolve(customer);
            } catch(e) {
                reject({msg: 'Failed to update customer', locale_str: 'store.update_failed'})
            }
        })
    },

    async removeCustomer({commit}, customer) {
        return new Promise(async (resolve, reject) => {
            try {
                const { error } = await supabase
                    .from(CUSTOMER_TABLE)
                    .delete()
                    .eq('id', customer.id)
                commit('REMOVE_CUSTOMER', customer);
                if(error) throw error;
                resolve();
            } catch(e) {
                reject({msg: 'Failed to delete customer', locale_str: 'store.remove_failed'})
            }
        })
    },
    async initializeCustomers({commit, state}) {
        return new Promise(async(resolve, reject) => {
            try {
                if(state.customers) {
                    return;
                }
                const { data, error } = await supabase
                    .from(CUSTOMER_TABLE)
                    .select();
                if(error) throw error
                commit('SET_CUSTOMERS', data);
                resolve();
            } catch(e) {
                reject({msg: 'Failed to initialize customers', locale_str: 'store.customers.init_failed'})
            }
        });
    },
}
const getters = {
    getCustomers: state => state.customers ? state.customers : [],
}
const mutations = {
    SET_CUSTOMERS(state, customers) {
        state.customers = customers
    },
    SET_CUSTOMER(state, customer) {
        state.customers.push(customer);
    },
    REMOVE_CUSTOMER(state, customer) {
        state.customers.splice(state.customers.indexOf(customer), 1)
    },
    UPDATE_CUSTOMER(state, customer) {
        const item = state.customers.find(c => c.id == customer.id)
        Object.assign(item, customer);
    }
};
export default {
    state,
    getters,
    mutations,
    actions
  };