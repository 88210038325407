<template>
    <div>
      <div class="title my-5">Glömt ditt lösenord?</div>
      <v-alert type="success" v-if="done">
        {{ $t('forgot_password.reset_email_sent') }}
      </v-alert>
        <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form @submit.prevent="submit">
                <validation-provider v-slot="{ errors, validate }" name="Email" rules="required|email">
                <v-text-field
                  v-focus="true"
                    v-model="email"
                    :error-messages="errors"
                    :label="$t('forgot_password.email')"
                    @input="validate"
                    required
                ></v-text-field>
                </validation-provider>
                <div class="text-center">
                <div class="red--text mb-2">{{getResetError != null ? "Failed to reset error! Are you sure the email you have entered is registered as a user?": ''}}</div>
                <v-btn :loading="getLoading" :disabled="invalid || done" type="submit" color="primary" block>
                    {{ $t('forgot_password.reset_my_password') }}
                </v-btn>
                </div>
            </v-form>
        </validation-observer>
        <div class="mt-5 caption text-right">
            <router-link to="/auth">{{ $t('forgot_password.back_to_sign_in') }}</router-link>
      </div>
    </div>
</template>

<script>
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from "vuex";
import { Focusable } from '@/components/directives/focus'
setInteractionMode('eager')

extend('email', {
  ...email,
  message: 'Email must be valid'
})
export default {
  directives: { focus: Focusable },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    email: '',
    done: false
  }),
  computed: {
    ...mapGetters([
      'getResetError',
      'getLoading'
    ])
  },
  methods: {
    ...mapActions(['resetAction']),
    async submit() {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        this.reset()
      }
    },
    toggleReset() {
      this.$emit('toggleview')
    },
    clear() {
      this.email = ''
      this.$refs.observer.reset()
    },
    async reset() {
      let res = await this.resetAction(this.email)
      if(res) {
        this.done = true
      }
    }
  }
}
</script>
