import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg d-flex flex-md-row flex-grow-1"},[_c(VRow,{staticClass:"d-flex justify-center align-center"},[_c(VCol,{attrs:{"xl":"3"}},[_c(VCard,{staticClass:"pa-4"},[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{staticClass:"d-flex justify-center"},[_c('UponorLogo',{attrs:{"height":70}})],1)],1),_c(VCardTitle,{staticClass:"text-h5 justify-center"},[_vm._v(" Prefab Online ")]),_c(VCardText,{staticClass:"text-subtitle-1"},[_c('p',[_vm._v(_vm._s(_vm.$t('accept_invitation.information')))]),_c('OtpInput',{attrs:{"length":6,"loading":_vm.loadingOtp},on:{"validate":_vm.validateOtp,"input":function($event){_vm.error = false}},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),(_vm.error)?_c('div',{staticClass:"text-center mt-1 red--text"},[_vm._v(_vm._s(_vm.$t('accept_invitation.invalid_code')))]):_vm._e(),_c(VRow,{staticClass:"mt-1"},[(_vm.loadingOtp)?_c(VCol,{staticClass:"d-flex flex-column align-center",attrs:{"cols":"12"}},[_c(VSubheader,[_vm._v(_vm._s(_vm.$t('accept_invitation.please_wait_message')))]),_c(VProgressCircular,{attrs:{"size":"42","indeterminate":"","color":"primary"}})],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }