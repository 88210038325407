<template>
    <v-container fluid>
        <v-row>
            <v-col class="d-flex flex-row" cols="12" xl="8">
                <v-btn :x-large="$vuetify.breakpoint.mdAndUp" class="mr-5" color="accent" to="/orders/new">{{ $t('orders.new_order') }}</v-btn>
                <v-spacer></v-spacer>
                <div class="mr-5">
                    <FileList/>
                </div>
                <ContactUs />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xl="8">
                <v-card>
                    <v-card-title><span class="primary--text">{{ $t('orders.title') }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :loading="initializeLoading"
                            :headers="headers"
                            :items="orders"
                            :search="filter.search">
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="12" md="10" lg="4">
                                        <v-text-field hide-details dense outlined append-icon="mdi-magnify" v-model="filter.search" label="Sök"></v-text-field>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="12" md="4" lg="2">
                                        <v-select dense outlined v-model="filter.status.selected" :items="filter.status.items" class="notranslate" item-text="label" append-icon="mdi-filter" item-value="value" label="Statusfilter"></v-select>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item.status="{ item }">
                                <v-chip small :color="getStatusColor(item.status)">
                                    {{ getStatusText(item.status) }}
                                </v-chip>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon
                                    small
                                    color="error"
                                    v-if="item.status == 'pending'"
                                    class="mr-2"
                                    @click="cancelOrder(item)">
                                    mdi-delete
                                </v-icon>
                                <v-icon
                                    small
                                    @click="viewOrder(item)">
                                    mdi-eye
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ContactUs from '@/components/ContactUsPopover.vue'
import FileList from '@/components/FileListPopover.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
    components: { ContactUs, FileList },
    name: 'Orders',
    data: function() {
        return {
            supportMenu: false,
            filter: {
                search: '',
                status: {
                    selected: null,
                    items: [
                        { 'label': this.$i18n.t('orders.status.all'), value: null},
                        { 'label': this.$i18n.t('orders.status.pending'), value: 'pending'},
                        { 'label': this.$i18n.t('orders.status.cancelled'), value: 'cancelled'},
                        { 'label': this.$i18n.t('orders.status.received'), value: 'received'},
                        { 'label': this.$i18n.t('orders.status.working'), value: 'working'},
                        { 'label': this.$i18n.t('orders.status.confirmed'), value: 'confirmed'},
                        { 'label': this.$i18n.t('orders.status.staged'), value: 'staged'},
                        { 'label': this.$i18n.t('orders.status.delivered'), value: 'delivered'},
                    ]
                }
            },
            initializeLoading: false
        }

    },
    async created() {
        this.initializeLoading = true;
        this.initializeOrders(this.getIsAdmin).then(() => this.initializeLoading = false)
    },
    computed: {
        ...mapGetters(['getOrders', 'getCurrentUser', 'getIsAdmin']),
        headers() {
            let headers = [
                {
                    text: this.$t('orders.table.headers.project_name'),
                    value: 'project_name',
                    sortable: true
                },
                {
                    text: this.$t('orders.table.headers.project_part'),
                    value: 'project_part',
                    sortable: true
                },
                {
                    text: this.$t('orders.table.headers.project_number'),
                    value: 'project_number',
                    sortable: true
                },
                {
                    text: this.$t('orders.table.headers.desired_delivery_date'),
                    value: 'desired_delivery_date',
                    sortable: true
                },
                {
                    text: this.$t('orders.table.headers.set_delivery_date'),
                    value: 'delivery_date',
                    sortable: true
                },
                {
                    text: this.$t('orders.table.headers.status'),
                    value: 'status',
                    sortable: true
                },
                {
                    text: this.$t('orders.table.headers.hash'),
                    value: 'hash',
                    sortable: false
                },
            ]
            if(this.getIsAdmin) {
                headers.push({
                    text: this.$t('orders.table.headers.created_by'),
                    value: 'users.name',
                    sortable: true
                }, {
                    text: this.$t('orders.table.headers.company'),
                    value: 'companies.name',
                    sortable: true})
            }
            headers.push({
                    text: this.$t('orders.table.headers.actions'),
                    value: 'actions',
                    align: 'right',
                    sortable: false, width: '150px'
                })
            return headers
        },
        orders() {
            return this.getOrders.filter(o => o.status == this.filter.status.selected || !this.filter.status.selected)
        }
    },
    methods: {
        ...mapActions(['initializeOrders', 'updateOrderStatus']),
        getStatusColor(status) {
            let statusColorMap = {
                'pending': 'grey lighten-2',
                'cancelled': 'error',
                'working': 'info',
                'received': 'info',
                'confirmed': 'info',
                'staged': 'success',
                'delivered': 'grey lighten-2'
            }
            return statusColorMap[status]
        },
        getStatusText(status) {
            return this.$t(`orders.status.${status}`)
        },
        async cancelOrder(order) {
            try {
                await this.updateOrderStatus({order, newStatus: 'cancelled'})
            } catch (e) {
            }
        },
        viewOrder(order) {
            this.$router.push(`/orders/view/${order.id}`)
        }
    }
}
</script>
<style lang="scss">
.v-data-table__wrapper {
    
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid #f5f5f5;
    padding:5px;
    .v-data-table__mobile-row__header {
        font-weight: normal;
        color:rgba(0,0,0,1)
    }
    .v-data-table__mobile-row__cell {
        text-align: left;
    }
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &:nth-child(odd) {
        border-left: 2px solid var(--v-primary-base);
    }
    &:nth-child(even) {
        border-left: 2px solid var(--v-accent-base);;
    }
}
</style>