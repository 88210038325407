import { supabase } from "@/plugins/supabase";

import router from "@/router";
const state = {
    information: null,
    session: null,
    user: null,
    authErrors: null,
    resetError: null
  };
const actions = {
    async signInAction({ commit, dispatch }, user) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data, error } = await supabase.auth.signInWithPassword({
                    email: user.email,
                    password: user.password
                })
                
                if(error) throw error;

                dispatch('retrieveCurrentUser', data)

                commit('SET_AUTH_ERRORS', null)
                resolve(data);
            } catch(error) {
                commit('SET_AUTH_ERRORS', error)
                reject(error);
            }
        })
    },
    async setUserPassword({ commit }, password) {
        const { user, error } = await supabase.auth.updateUser({password: password})
        if(error) {
            console.error(error)
            return false;
        } 
        return true;
    },
    async verifyOtp({commit}, {email, token, type}) {
        try {
                const {data, error} = await supabase.auth.verifyOtp({
                email,
                token,
                type
            })
            if(error) throw error;
            return true;
        } catch(e) {
            throw e
            return false;
        } 
        return false;
        
    },
    async retrieveCurrentUser({commit}, session) {
        try {
            const { data, error } = await supabase
                .from('users')
                .select(`*,companies(name, org_nr, invoice_address)`)
                .eq('id', session.user.id)
            if (error) throw error
            if(data[0])
                commit('SET_CURRENT_USER', data[0])
            Promise.resolve()
        } catch(e) {
            Promise.reject(e)
        }
    },
    async updateUserFromSession({commit, dispatch}, session) {
        dispatch('retrieveCurrentUser', session)
        commit('SET_SESSION', session)
    },
    async resetAction({ commit }, email) {
        commit('setLoading', true);
        try {
            const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
                redirectTo: window.location.origin + '/auth/set-password',
            })
            if(error) throw error;
            commit('setLoading', false);
            commit('SET_RESET_ERROR', null)
            return true;
        } catch(e) {
            console.error(e)
            commit('setLoading', false);
            commit('SET_RESET_ERROR', e)
            return false;
        }
    },
    async signoutAction({ commit} ) {
        try {
            const { error } = await supabase.auth.signOut();
            if(error) throw error;
            commit('SET_SESSION', null)
            commit('SET_CURRENT_USER', null)
        } catch(error) {

        }

    }
}
const getters = {
    getIsAdmin: (state, getters) => getters.getCurrentUser?.app_metadata?.claims_admin,
    getUserSession: state => state.session,
    getUserEmail: state => state.session?.user.email,
    getUserId: state => state.session?.user.id,
    getCurrentUser: state => state.session?.user,
    getAuthErrors: state =>  state.authErrors,
    getResetError: state => state.resetError,
    getUserObject: state => state.user
}
const mutations = {
    SET_SESSION(state, session) {
        state.session = session;
    },
    SET_CURRENT_USER(state, user) {
        state.user = user
    },
    SET_AUTH_ERRORS(state, errors) {
        state.authErrors = errors
    },
    SET_RESET_ERROR(state, error) {
        state.resetError = error
    },
    SET_INFORMATION(state, information) {
        state.information = information
    }
};
export default {
    state,
    getters,
    mutations,
    actions
  };