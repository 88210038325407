import Vue from 'vue';
const COLLECTION_NAME = "companies"
const CONFIGURATION_TABLE = "configurations"
const PRODUCT_GROUP_TABLE = 'product_groups'
import { v4 as uuidv4 } from 'uuid';
import { supabase } from "@/plugins/supabase";

const DEFAULT_CONFIGURATION = {
    name: null,
    name_notification: null,
    bom_string: null,
    price: 0,
    attributes: [],
    segment_id: '',
    status: 'draft',
    created_by_id: null,
    product_group_id: ''
}

const state = {
    configurations: [],
    configurationFiles: {

    },
    productGroups: [],
    currentConfiguration: DEFAULT_CONFIGURATION
};

const actions = {
    async saveConfiguration({commit, dispatch, getters}, newConfiguration) {
        return new Promise(async(resolve, reject) => {
            try {
                newConfiguration.created_by_id = getters.getUserId;
                
                const { data, error } = await supabase.from(CONFIGURATION_TABLE).insert([
                    newConfiguration
                ]).select().single()
                if(error) throw error;
                commit('SET_CONFIGURATION', data);
                commit('SET_CURRENT_CONFIGURATION', Object.assign({}, DEFAULT_CONFIGURATION))
                resolve(data);
            } catch(e) {
                reject({msg: 'Failed to add configuration', locale_str: 'store.configuration.add_failed'});
            }
        })
    },
    async setCurrentConfiguration({commit, state}, data) {
        let targetConfig = state.configurations.find(c => c.id == data.targetId);
        commit('SET_CURRENT_CONFIGURATION', Object.assign({}, targetConfig))
    },
    async updateConfiguration({commit}, configuration) {
        return new Promise(async(resolve, reject) => {
            try {
                const { data, error } = await supabase
                    .from(CONFIGURATION_TABLE)
                    .update(configuration)
                    .eq('id', configuration.id)
                    .select()
                    .single()
                if(error) throw error;
                commit('UPDATE_CONFIGURATION', data)
                resolve(data);
            } catch(e) {
                reject({msg: 'Failed to update configuration', locale_str: 'store.update_failed'})
            }
        })
    },
    async removeConfiguration({commit}, data) {
        return new Promise(async (resolve, reject) => {
            try {
                const { error } = await supabase
                    .from(CONFIGURATION_TABLE)
                    .delete()
                    .eq('id', data.id)
                if(error) throw error;
                commit('REMOVE_CONFIGURATION', data);
                resolve();
            } catch(e) {
                reject({msg: 'Failed to delete configuration', locale_str: 'store.remove_failed'})
            }
        })
    },
    async initializeConfigurations({commit, state}) {
        try {
            if(state.configurations.length > 0) {
                Promise.resolve()
                return;
            }
            const confRes = await supabase
                .from(CONFIGURATION_TABLE)
                .select()
            if(confRes.error) throw confRes.error;
            commit('SET_CONFIGURATIONS', confRes.data);
            Promise.resolve();
        } catch(e) {
            Promise.reject({msg: 'Failed to initialize configurations', locale_str: 'configurations.errors.init_failed'});
        }
    },
    async deleteConfigurationFile({commit}, file) {
        try {
            // Delete entity
            const { data, error } = await supabase
                .from('configuration_files')
                .delete()
                .eq('id', file.id)
            if(error) throw error;
            // Delete file from bucket
            const { data: fileData, error: fileError } = await supabase
                .storage
                .from('configuration-files')
                .remove([file.path])
            if(fileError) throw fileError;
            commit('DELETE_CONFIGURATION_FILE', file)
            Promise.resolve();
        } catch(e) {
            console.error(e);
            Promise.reject({msg: 'Failed to delete file', locale_str: 'configurations.errors.delete_failed'});
        }
    },
    async addConfigurationFile({commit}, {configuration, file}) {
        try {
            // Upload file to bucket
            const fileName = uuidv4();
            const { data: uploadData, error: uploadError } = await supabase.storage
                .from('configuration-files')
                .upload(fileName, file);
            if(uploadError) throw uploadError;
            // Add file to configuration_files table
            const { data: fileData, error: fileError } = await supabase
                .from('configuration_files')
                .insert([{configuration_id: configuration.id, friendly_name: file.name, path: fileName}])
                .select().single()
            if(fileError) throw fileError
            commit('ADD_CONFIGURATION_FILE', {configuration, file: fileData})
            Promise.resolve();
        } catch(e) {
            console.error(e);
            Promise.reject({msg: 'Failed to upload file', locale_str: 'configurations.errors.upload_failed'});
        }
    },
    async initializeConfigurationFiles({commit, getters}, configuration) {
        try {
            if(getters.getConfigurationFiles[configuration.id]) {
                return;
                Promise.resolve();
            }
            let { data, error } = await supabase
                .from('configuration_files')
                .select()
                .eq('configuration_id', configuration.id)
            if(error) throw error;
            commit('SET_CONFIGURATION_FILES', {data, configuration})
            Promise.resolve();
        } catch(e) {
            console.error(e);
            Promise.reject({msg: 'Failed to get files for configuration', locale_str: 'configurations.get_files_failed'});
        }
    }
}
const getters = {
    getConfigurations: state => state.configurations ? state.configurations : [],
    getConfigurationFiles : state => state.configurationFiles,
    getPublishedConfigurations: state => state.configurations ? state.configurations.filter(conf => conf.status === 'published') : [],
    getCurrentConfiguration: state => state.currentConfiguration,
}
const mutations = {
    SET_CONFIGURATIONS(state, data) {
        state.configurations = data
    },
    SET_CONFIGURATION_FILES(state, data) {
        Vue.set(state.configurationFiles, data.configuration.id, data.data)
    },
    ADD_CONFIGURATION_FILE(state, data) {
        Vue.set(state.configurationFiles, data.configuration.id, [...state.configurationFiles[data.configuration.id], data.file])
    },
    DELETE_CONFIGURATION_FILE(state, file) {
        state.configurationFiles[file.configuration_id].splice(state.configurationFiles[file.configuration_id].indexOf(file), 1);
    },

    SET_CONFIGURATION(state, data) {
        state.configurations.push(data);
    },
    SET_CURRENT_CONFIGURATION(state,data) {
        state.currentConfiguration = data
    },
    REMOVE_CONFIGURATION(state, data) {
        state.configurations.splice(state.configurations.indexOf(data), 1)
    },
    UPDATE_CONFIGURATION(state, data) {
        const item = state.configurations.find(s => s.id == data.id)
        Object.assign(item, data);
    }
};
export default {
    state,
    getters,
    mutations,
    actions
  };