<template>
    <div class="d-flex flex-column flex-md-row flex-grow-1">
      <div id="left" class="px-2 px-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
        <div class="ma-auto">
          <div class="d-flex d-md-none justify-center">
            <v-card class="login-card">
              <v-card-title class="justify-center pt-8 pb-0">
                <uponor-logo />
              </v-card-title>
              <v-card-text>
                <transition  name="fade" mode="out-in">
                  <router-view />
                </transition>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
      <div class="layout-side mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2 v-sheet theme-light">
        <div class="mt-10 pa-2">
         
          <div class="justify-center d-flex">
            <uponor-logo />
          </div>
          <transition  name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
        
      </div>
    </div>
  </template>
  
  <script>
  import UponorLogo from '@/components/logo-uponor.vue'
  export default {
    components: {
      UponorLogo
    },
    data: () => ({
      view: 'signin'
    }),
    methods: {
      toggleView(view) {
        this.view = view;
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  #left {
    background: url('~@/assets/IM2_3434_splash_min.jpg') no-repeat center center fixed; 
    background-size: cover;
  }
  .layout-side {
    width:400px;
  }
  .layout-content {
    
  }
  .login-card {
    width: 350px;
    max-width: 90%;
  }
  </style>
  