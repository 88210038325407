import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "./scss/main.scss"
import { supabase } from './plugins/supabase'
import i18n from './plugins/i18n'
import localizedVeeValidate from './plugins/vee-validate'

Vue.config.productionTip = false

const { data: authSubscription }  = supabase.auth.onAuthStateChange(async (event, session) => {
  store.dispatch('updateUserFromSession', session)
  if (event == 'SIGNED_OUT') router.go('/auth')
})

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify,
  i18n
}).$mount('#app')